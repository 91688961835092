<template>
  <!-- begin:: Header -->
  <div
    id="kt_header" ref="kt_header" class="header" style="background-color: #1358a6"
    v-bind:class="headerClasses"
  >
    <div class="container-fluid d-flex justify-content-between">
      <!-- LOGO -->
      <div class="">
        <img src="/media/logos/MTA_Logo.png" alt="MTA NSW"
             class="img-fluid p-2" style="max-height: 60px"
        />
      </div>

      <!-- WIZARD TITLE AND SUBTITLE -->
      <div class="text-sm-right text-md-center">
        <span class="text-light font-size-h2 font-weight-bold">
          {{ wizardTitle }}
        </span>
        <br />
        <span class="text-light font-size-h4 font-weight-bold">
          {{ wizardSubtitle }}
        </span>
      </div>

      <!-- FIXED WIDTH DIV TO OFFSET THE LOGO SIZE FROM THE RIGHT SIDE -->
      <div class="w-md-225px"></div>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

export default {
  name: "KTHeader",
  components: {},
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    wizardTitle: function () {
      let state = this.$store.state;
      // Set page title
      if (state.wizardTitle) {
        document.title = state.wizardTitle;
      }

      return state.wizardTitle;
    },

    wizardSubtitle: function () {
      return this.$store.state.wizardSubtitle;
    },

    transactionStatus: function () {
      return this.$store.state.transactionStatus;
    }
  },

  methods: {},
};
</script>
