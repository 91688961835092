<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-2 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-center"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="nav nav-dark">
        <span class="text-muted font-weight-bold mr-2">
          &nbsp;&copy;&nbsp;{{ currentYear }}
        </span>
        <a href="https://www.mtansw.com.au/" target="_blank" class="text-dark-75 text-hover-primary">
          MTA NSW
        </a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },

    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
